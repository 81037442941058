body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.header {
  background-color: #A3BCC0;
  color: #000000;
  display: flex;
  align-items: center;
  padding: 10px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.logo img {
  width: 75px;
  /* Ajustez la taille du logo selon vos besoins */
  height: auto;
}

.time-left {
  position: absolute;
  top: 18%;
  right: 4%;

}

.time-left img {
  width: 50px;
  /* Ajustez la taille du logo selon vos besoins */
  height: auto;
}

.title {
  margin-left: 10px;
}

.title h1 {
  font-size: 1.4rem;
  margin: 0;
}

.title p {
  font-size: 0.8rem;
  margin: 0;
}

.menu-line {
  top: 90px;
  left: 0;
  position: fixed;
  width: 100%;
  padding-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #c9dbd1;
  color: #234E51;
  padding: 5px 10px;
}


.barman {
  font-style: italic;
}

.section-title {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.menu-title {
  font-size: 1.0rem;
  margin-right: 10px;
  margin-left: 18px;
  color: #234E51;
}

.line {
  flex-grow: 1;
  height: 1.5px;
  background-color: #234E51;
  margin-right: 5px;
}

.order-specs {
  display: flex;
  align-items: center;
  background-color: #E0E9E4;
  margin: 10px 10px 10px 10px;
  border-radius: 5px;
  border: 3px solid transparent;
}

.order-details {
  width: 60%;
  height: auto;
  margin-right: 10px;
  margin-left: 15px;
  margin-bottom: 4px;
}

.order-number {
  font-size: 1.2rem;
  color: #578531;
  padding-top: 10px;
}

.order-details-progress {
  width: 50%;
  height: auto;
  margin-right: 10px;
  margin-left: 15px;
}

.order-drinks {
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.9rem;
  margin-bottom: 5px;
  font-style: italic;
  color: #234E51;
  padding-top: 4px;
}

.order-payment {
  display: flex;
  flex-grow: 0.3;
  flex-direction: column;
  align-items: left;
}

.order-price {
  font-size: 1.2rem;
  color: #234E51;
  padding-top: 10px;
  text-align: left;
}

.order-payment-method {
  font-size: 0.9rem;
  margin-bottom: 5px;
  color: #234E51;
  padding-top: 4px;
  text-align: left;
}

.order-time {
  display: flex;
  flex-direction: column;
  flex-grow: 0.3;
  align-items: center;
}

.hourglass {
  padding-top: 10px;

}

.hourglass img {
  max-width: 15px;
}

.time {
  font-size: 0.9rem;
  margin-bottom: 5px;
  color: #234E51;
  padding-top: 9px;
}

.resp {
  border: 3px solid #578531;
  background-color: #5785315e;
}

.messages {
  padding-top: 20%;
}

.message {
  color: #234E51;
  font-size: 1.3rem;
  text-align: left;
  margin: 4% 13%;
}

.message img {
  width: 8%;
  display: inline;
  margin-left: 5px;
  margin-bottom: 2px;
}

.price {
  font-size: 1.2rem;
}

.quantity {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  text-align: center;
}

.increment {
  font-size: 2.5rem;
  cursor: pointer;
  margin-bottom: -8px;
  color: #234E51;
}

.decrement {
  font-size: 3.5rem;
  cursor: pointer;
  margin-top: -28px;
  color: #234E51;
  margin-bottom: -10px;
}

.count {
  font-size: 1.25rem;
}

.all {
  padding-bottom: 60%;
  padding-top: 120px;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 9%;
  background-color: #A3BCC0;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-image {
  width: 13%;
  height: auto;
  padding: 5px;
}

.underlined {
  border-bottom: 3px solid #234E51;
  margin-top: 15px;
  width: 12%;
  padding-bottom: 10px;
}

.vl {
  width: 2px;
  height: 75%;
  background-color: #234E51;
  margin: 0 7%;
}

.button-container {
  display: flex;
  justify-content: center;
  /* Centre les éléments horizontalement */
}

.button {
  font-size: 1.1rem;
  background-color: #A3BCC0;
  color: #234E51;
  width: 22%;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  padding: 15px 20px;
  margin: 0 20px;
  /* Espacement entre les boutons */
  margin-bottom: 5px;
}

.disabled {
  background-color: #c4d1d3;
  color: #6e7e80;
  cursor: initial;
}