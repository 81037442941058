body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.header {
  background-color: #A3BCC0;
  color: #000000;
  display: flex;
  align-items: center;
  padding: 10px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.logo img {
  width: 75px;
  height: auto;
}

.new-time-left {
  position: fixed;
  top: 2%;
  z-index: 2;
  right: 4%;
  z-index: 2;
}

.new-time-left img {
  width: 50px;
  height: auto;
}

.title {
  margin-left: 10px;
}

.title h1 {
  font-size: 1.4rem;
  margin: 0;
}

.title p {
  font-size: 0.8rem;
  margin: 0;
}

.menu-line {
  top: 90px;
  left: 0;
  position: fixed;
  width: 100%;
  padding-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #c9dbd1;
  color: #234E51;
  padding: 5px 10px;
}

.menu {
  font-size: 1.4rem;
}

.transat {
  font-style: italic;
}

.section-title {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.menu-title {
  font-size: 1.0rem;
  margin-right: 10px;
  margin-left: 18px;
  color: #234E51;
}

.line {
  flex-grow: 1;
  height: 1.5px;
  background-color: #234E51;
  margin-right: 5px;
}

.total {
  position: fixed;
  left: 0;
  bottom: 8%;
  width: 100%;
  background-color: #c9dbd1;
  padding-bottom: 5%;
}

.total-line {
  flex-grow: 1;
  height: 2px;
  background-color: #234E51;
  margin: 4% 5%;
}

.total-text {
  font-size: 1.2rem;
  color: #234E51;
  margin: 4% 5%;
  text-align: center;
}

.total-price {
  color: #578531;
  padding-left: 5px;
}

.cocktail-specs {
  display: flex;
  align-items: center;
  background-color: #E0E9E4;
  margin: 10px 10px 10px 10px;
  border-radius: 5px;

}

.cocktail-image img {
  max-width: 60px;
  height: auto;
  margin-right: 10px;
  margin-left: 15px;
}

.cocktail-details {
  flex-grow: 1;
  margin-left: 5px;
  margin-bottom: 25px;
}

.cocktail-name {
  font-size: 1.2rem;
  font-weight: bold;
  color: #234E51;
}

.ingredients {
  font-size: 0.9rem;
  margin-bottom: 5px;
  font-style: italic;
  color: #234E51;
}

.cocktail-price {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 10px;
  margin-bottom: 45px;
  color: #234E51;
}

.messages {
  padding-top: 20%;
}

.message {
  color: #234E51;
  font-size: 1.3rem;
  text-align: left;
  margin: 4% 13%;
}

.message img {
  width: 8%;
  display: inline;
  margin-left: 5px;
  margin-bottom: 2px;
}

.price {
  font-size: 1.2rem;
}

.quantity {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  text-align: center;
}

.increment {
  font-size: 2.5rem;
  cursor: pointer;
  margin-bottom: -8px;
  color: #234E51;
}

.decrement {
  font-size: 3.5rem;
  cursor: pointer;
  margin-top: -28px;
  color: #234E51;
  margin-bottom: -10px;
}

.count {
  font-size: 1.25rem;
}

.all {
  padding-bottom: 60%;
  padding-top: 120px;
}

.vl {
  width: 3px;
  height: 75%;
  background-color: #234E51;
  margin: 0 18%;
}

.cart-button-container {
  display: flex;
  justify-content: center;
}

.cart-button {
  font-size: 1.1rem;
  background-color: #A3BCC0;
  color: #234E51;
  width: 40%;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  padding: 15px 20px;
  margin: 0 20px;
  margin-bottom: 5px;
}

.disabled {
  background-color: #c4d1d3;
  color: #6e7e80;
  cursor: initial;
}