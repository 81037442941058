body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.header {
  background-color: #A3BCC0;
  color: #000000;
  display: flex;
  align-items: center;
  padding: 10px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.logo img {
  width: 75px;
  /* Ajustez la taille du logo selon vos besoins */
  height: auto;
}

.title {
  margin-left: 10px;
}

.title h1 {
  font-size: 1.4rem;
  margin: 0;
}

.title p {
  font-size: 0.8rem;
  margin: 0;
}

.menu-line {
  top: 90px;
  left: 0;
  position: fixed;
  width: 100%;
  padding-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #c9dbd1;
  color: #234E51;
  padding: 5px 10px;
}

.menu {
  font-size: 1.4rem;
}

.transat {
  font-style: italic;
}

.section-title {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.menu-title {
  font-size: 1.0rem;
  margin-right: 10px;
  margin-left: 18px;
  color: #234E51;
}

.line {
  flex-grow: 1;
  height: 1.5px;
  background-color: #234E51;
  margin-right: 5px;
}

.cocktail-specs {
  display: flex;
  align-items: center;
  background-color: #E0E9E4;
  margin: 10px 10px 10px 10px;
  border-radius: 5px;
}

.cocktail-image img {
  max-width: 60px;
  height: auto;
  margin-right: 10px;
  margin-left: 15px;
}

.cocktail-details {
  flex-grow: 1;
  margin-left: 5px;
  margin-bottom: 25px;
}

.cocktail-name {
  font-size: 1.2rem;
  font-weight: bold;
  color: #234E51;
}

.ingredients {
  font-size: 0.9rem;
  margin-bottom: 5px;
  font-style: italic;
  color: #234E51;
}

.cocktail-price {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 10px;
  margin-bottom: 45px;
  color: #234E51;
}

.edit {
  min-width: 10%;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  text-align: center;
}

.edit img {
  width: 30px;
}

.price {
  font-size: 1.2rem;
}

.all {
  padding-bottom: 75px;
  padding-top: 120px;
}


.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 9%;
  background-color: #A3BCC0;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-image {
  width: 13%;
  height: auto;
  padding: 5px;
}

.underlined {
  border-bottom: 3px solid #234E51;
  margin-top: 15px;
  width: 12%;
  padding-bottom: 10px;
}

.vl {
  width: 2px;
  height: 75%;
  background-color: #234E51;
  margin: 0 7%;
}

.add-drink {
  position: fixed;
  top: 2%;
  right: 4%;
  z-index: 2;
}

.add-drink img {
  width: 50px;
  height: auto;
}