/* styles.css */
/* Nav bar styles */
#header {
    position: fixed;
    width: 100%;
    z-index: 30;
    top: 0;
    background-color: white;
    color: white;
    /* Add more styles as needed */
}

#header .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 0;
    padding-top: 2rem;
}

#header .pl-4 {
    display: flex;
    align-items: center;
}

#header .toggleColour {
    color: white;
    text-decoration: none;
    /* Add more styles as needed */
}

#header .toggleColour img {
    height: 2rem;
    vertical-align: middle;
    fill: currentColor;
    margin-right: 0.5rem;
}

#header #nav-toggle {
    display: none;
    /* Add more styles as needed */
}

#header .flex-grow {
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 2rem;
    background-color: white;
    /* Add more styles as needed */
}

#header .lg:bg-transparent {
    background-color: transparent;
    /* Add more styles as needed */
}

#header ul {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    flex: 1;
    align-items: center;
    /* Add more styles as needed */
}

#header ul li {
    margin-right: 1rem;
    /* Add more styles as needed */
}

#header ul li:last-child {
    margin-right: 0;
    /* Add more styles as needed */
}

#header a {
    color: black;
    text-decoration: none;
    /* Add more styles as needed */
}

#header .mx-auto {
    margin-left: auto;
    margin-right: auto;
}

#header #navAction {
    margin-top: 1rem;
    background-color: white;
    color: gray;
    font-weight: bold;
    border-radius: 9999px;
    padding: 1rem 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    opacity: 0.75;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

#header #navAction:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

#header hr {
    border-color: gray;
    opacity: 0.25;
    margin: 0;
    padding: 0;
    border-bottom-width: 1px;
}

#gradient {
        background: linear-gradient(90deg, #47a8af 0%, #E0E9E4 100%);
      }
/* Add more styles as needed */
  
/* Reset some default styles */
body, ul {
    margin: 0;
    padding: 0;
  }
  
  /* Set a white background for the page */
  body {
    background-color: #fff;
  }
  
  /* Style the nav bar */
  nav {
    background-color: #fff; /* White background for the nav bar */
    color: #333; /* Dark text color for the nav bar */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  }

  .contact-title {
    text-align: center;
    font-size: 2.3em;
    font-weight: bold;
    padding-bottom: 30px;
  }
  
  .logo img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  
  .logo span {
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .nav-links {
    list-style-type: none;
    display: flex;
    align-items: center;
  }
  
  .nav-links li {
    margin-right: 20px;
  }
  
  .nav-links a {
    text-decoration: none;
    color: #333; /* Dark text color for the nav links */
    display: flex;
    align-items: center;
  }

  .need-white {
    color: white;
  }
  
  .nav-links img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  
  .login-button {
    background-color: #333; /* Dark background for the login button */
    color: #fff; /* Light text color for the login button */
    border: none;
    padding: 8px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
  }

/* Style the team container */
.team {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  /* Style the team member */
  .team-member {
    flex: 1;
    max-width: 300px;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    margin: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .team-member img {
    width: 75%;
    max-height: auto;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
  }
  
  .team-member h2 {
    margin: 5px 0;
    font-size: 1.5rem;
  }
  
  .team-member p {
    margin: 5px 0;
    color: #666;
  }

  .mockup-image-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom:30px;
  }

  .image-box {
    flex: 1;
    max-width: 300px;
    padding: 20px;
    margin: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .image-box h3 {
    font-size: 1.4em; /* Agrandi la taille du titre */
    margin-top: 10px; /* Ajout d'un espace entre le titre et l'image */
    margin-bottom: 20px;
  }

  .image-box img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  .titlesss {
    text-align: center;
    font-size: 2.3em;
    font-weight: bold;
  }

  .contact-form {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

 .input-form {
    display: block;
    margin-bottom: 10px;
}

 .info {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 16px;
 }

 .contact-div {
    padding:30px;
    background-color: white;
 }

 .submit-button {
    display: block;
    background-color: #47a8af;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
 }

 .submit-button:hover {
    background-color: #34949b;
 }

 .contact-info {
    display: flex;
    justify-content: space-between;
    font-size: 1.8rem;
    flex-wrap: wrap;
    padding-top: 50px;
    padding-bottom: 30px;
    background-color: white;
    text-align: center;
 }

 .label-message {
    margin-bottom : -80px;
 }