.client-order-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.client-order-popup-content {
  background-color: #E0E9E4;
  padding: 20px;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 70%;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.client-order-close-button {
  width: 30px;
  height: 30px;
  position: absolute;
  padding: 2px;
  top: 5px;
  right: 5px;
  border: 1px solid;
  border-radius: 50%;
  cursor: pointer;
}

.client-order-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 8%;
  width: 70%;
}

.client-order-dot {
  width: 11px;
  height: 11px;
  margin: 5px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
}

.client-order-dotDoing {

  background: #A3BCC0;
  border-color: #A3BCC0;
}

.client-order-dotDone {
  background: #578531;
}

.client-order-status {
  color: #234E51;
  width: 100%;
  border: 1px solid;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
}

.client-order-waiting {
  border-color: #234E51;
  background: #E0E9E4;
  color: #234E51;
}

.client-order-doing {
  border-color: #234E51;
  background: #A3BCC0;
  color: #234E51;
}

.client-order-done {
  border-color: #578531;
  color: #578531;

}