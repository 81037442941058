.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: #E0E9E4;
  color: #234E51;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 80%;
  text-align: left;
  position: relative;
  height: max-content;
  width: 77.5%;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.input_box {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 5px 0;
  width: 95%;
}

.input_box label {
  width: 70px;
  margin-top: 0;
  text-align: left;
}

.input_box input {
  border: 1px solid #234E51;
  padding: 5px;
  margin: 5px -5px;
  margin-left: 5px;
  background-color: #E0E9E4;
  width: 100%;
}

.upload-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  border-radius: 0px;
  margin: 10px 5px;
  border-color: #234E51;
  border-width: 1px;
}

.upload-img img {
  border: 0px solid #234E51;
  width: 50%;
  height: auto;
}

.input_description {
  border: 1px solid #234E51;
  /* Add a black border around the input */
  padding: 5px;
  margin: 5px 0;
  background-color: #E0E9E4;
  width: auto;
  height: 100px;
  /* Adjust the height as needed */
  overflow-y: auto;
  /* Add scrollbar if content overflows vertically */

}


/* Style for the scrolling menu */
.scrolling-menu {
  max-height: 150px;
  overflow-y: auto;
  border: 1px solid #234E51;
  /* Add a black border around the select */
  padding: 5px;
  margin: 5px 5px;
  background-color: #E0E9E4;
}

/* Style for buttons */

.buttons_bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  padding: 0 10%;
  margin-bottom: 3%;
  margin-top: 5%;
}

.buttons_bottom button {
  background-color: #A3BCC0;
  width: 43%;
  color: #234E51;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px 10px;
  /* Added padding for button styling */

}