body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.header {
  background-color: #A3BCC0;
  color: #000000;
  display: flex;
  align-items: center;
  padding: 10px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.logo img {
  width: 75px;
  height: auto;
}

.time-left {
  position: absolute;
  top: 18%;
  right: 4%;
}

.time-left img {
  width: 50px;
  height: auto;
}

.title {
  margin-left: 10px;
}

.title h1 {
  font-size: 1.4rem;
  margin: 0;
}

.title p {
  font-size: 0.8rem;
  margin: 0;
}

.menu-line {
  top: 90px;
  left: 0;
  position: fixed;
  width: 100%;
  padding-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #c9dbd1;
  color: #234E51;
  padding: 5px 10px;
}

.barman {
  font-style: italic;
}

.section-title {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 1.5rem;
}

.menu-title {
  font-size: 1.2rem;
  margin-right: 10px;
  margin-left: 18px;
  color: #234E51;
}

.transat-number {
  color: #234E51;
  font-size: 1.4rem;
  background-color: #c9dbd1;
  border-radius: 5px;
  margin: 5px;
  padding: 5px 8px 5px 8px;
  margin-left: 15px;
  margin-top: 15px;
  width: max-content;
}

.transate-number-nb {
  font-weight: bold;
}

.order-list {
  list-style-type: none;
  color: #234E51;
  margin: 20px 30px 0px 20px;
}

.order-item {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin: 0px 0px 10px 0px;
}

.order-details {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  margin-left: 30px;
}

.order-quantity {
  margin-right: 20px;
}

.line {
  flex-grow: 1;
  height: 1.3px;
  background-color: #234E51;
  margin-right: 10px;
  margin-left: 10px;
}

.stat-info {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  margin: 10px 30px 10px 15px;
  color: #234E51;
}

.stat-primary {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-grow: 1;
}

.stat-type {
  display: flex;
  flex-grow: 1;
}

.stat-more {
  text-align: right;
}

.all {
  padding-bottom: 60%;
  padding-top: 120px;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 9%;
  background-color: #A3BCC0;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-image {
  width: 13%;
  height: auto;
  padding: 5px;
}

.underlined {
  border-bottom: 3px solid #234E51;
  margin-top: 15px;
  width: 12%;
  padding-bottom: 10px;
}

.vl {
  width: 2px;
  height: 75%;
  background-color: #234E51;
  margin: 0 7%;
}

.order-button-container {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.two-buttons {
  justify-content: space-between;
  margin-left: 10%;
  margin-right: 10%;
}

.order-button {
  font-size: 1.1rem;
  background-color: #A3BCC0;
  color: #234E51;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  padding: 15px 20px;
}

.disabled {
  background-color: #c4d1d3;
  color: #6e7e80;
  cursor: initial;
}

.order-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.order-dot {
  width: 11px;
  height: 11px;
  margin: 5px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
}

.order-dotWaiting {
  background: #A3BCC0;
}

.order-dotDone {
  background: #578531;
}

.order-status {
  width: 100%;
  border: 1px solid;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
}

.order-waiting {
  border-color: #c3e6cb;
  color: #234E51;
}

.order-doing {
  border-color: #c3e6cb;
  background: #e1e9ea;
  color: #234E51;
}

.order-done {
  border-color: #cae3b5;
  color: #578531;
}