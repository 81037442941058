.delete-drink-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete-drink-popup-content {
  background-color: #E0E9E4;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 80%;
  text-align: center;
  position: relative;
}

.delete-drink-close-button {
  width: 30px;
  height: 30px;
  position: absolute;
  padding: 2px;
  top: 5px;
  right: 5px;
  border: 1px solid;
  border-radius: 50%;
  cursor: pointer;
}

.delete-drink-container {
  height: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.delete-drink-popup-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.delete-drink-popup-button {
  font-size: 1.3rem;
  background-color: #A3BCC0;
  color: #234E51;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  padding: 15px 20px;
  margin: 5px;
  width: 80%;
  margin-bottom: 20px;
}

.delete-drink-popup-title {
  margin-left: 5px;
  margin-top: 50px;
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #234E51;
}